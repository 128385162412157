import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { PageProps } from 'gatsby';

const TestGround = (props: PageProps) => {

  const theme = useTheme();
  console.log(props.data);

  React.useEffect(() => {
    const testScope = 'hey there';

    return () => {}
  }, [])

  return (
    <>
      <h2>Testground</h2>
      {`spacing: ${theme.spacing(1)}px`}
    </>
  )
}

export default TestGround